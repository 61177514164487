import * as React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { Buffer } from 'buffer'

globalThis.Buffer = Buffer

function importBuildTarget() {
  // DefinePlugin in webpack.config.js will substitute
  // process.env.REACT_APP_RENDER with it's value at build time.
  // https://webpack.js.org/plugins/define-plugin/

  // TerserPlugin in webpack.config.js will eliminate dead code
  // ...if we make it easy enough (no maps or switches, etc).
  // https://webpack.js.org/plugins/terser-webpack-plugin/

  if (process.env.REACT_APP_RENDER === 'admin-panel') {
    import('./admin/i18n')
    import('./user/scss/main.scss')
    return Promise.all([import('./admin/routes/index.jsx'), import('./admin/store/store')])
  } else if (process.env.REACT_APP_RENDER === 'user-panel') {
    import('./user/i18n')
    import('./user/scss/main.scss')
    return Promise.all([import('./user/routes/index.jsx'), import('./user/store/store')])
  } else {
    return Promise.reject(new Error('No such build target: ' + process.env.REACT_APP_RENDER))
  }
}

if (process.env.REACT_APP_SENTRY_KEY && process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
  })
}

importBuildTarget().then(([EnvironmentResult, StoreResult]) => {
  if (StoreResult) {
    const container = document.getElementById('root')
    const root = createRoot(container)
    return root.render(
      <Provider store={StoreResult.default}>
        <EnvironmentResult.AppRouter />
      </Provider>,
    )
  } else {
    window.location.reload()
  }
})
